
import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    nextTick,
    onMounted,
} from 'vue';
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import downloadFile from '@/utils/claim/downloadFile';
import moment, { Moment } from 'moment';
// import EditEnquiry from "@/views/Claim/EnquiryProgram/EditEnquiryProgram";
import {
    getDelayCallBackRuleHistory,
    getPeriody,
    addCallBackRule,
    closeRuleHistory,
} from '@/API/checking/delayCallBackRuleSetup';
import { AFCandLCApproveTableData } from '@/views/Payment/Mgmt/types';
import { dateCompare } from '@/utils/claim/dateCompare';
const tableWidth = window.innerWidth - 350;
// const tableWidth = window.innerWidth
const columns = reactive([
    {
        title: 'Call Back Object',
        align: 'left',
        dataIndex: 'callBackObject',
        width: 80,
    },
    {
        title: 'Retail/Claim Period',
        align: 'left',
        dataIndex: 'period',
        width: 100,
    },
    {
        title: 'Status',
        align: 'left',
        dataIndex: 'status',
        width: 80,
        slots: { customRender: 'status' },
    },
    { title: 'Open Date', align: 'left', dataIndex: 'openDate', width: 80 },
    { title: 'End Date', align: 'left', dataIndex: 'endDate', width: 80 },
    {
        title: 'Operation',
        align: 'left',
        dataIndex: 'checkResult',
        width: 80,
        slots: { customRender: 'operation' },
    },
]);
export default defineComponent({
    components: {},
    setup() {
        const dateFormat = 'YYYY-MM-DD';
        const tableHeight = ref();
        const searchCon = ref();
        const queryListData = ref<any[]>([]);
        const selectOpations = ref<any[]>([]);
        const selectPeriodFrom = ref<any>([]);
        const selectPeriodTo = ref<any>([]);
        const dateMode = ref<any>(['month', 'month']);
        const formLst = reactive<any>({
            callbackObjType: '',
            periodSelect: null,
        });
        //筛选条件，各个信息框的内容
        const queryListParams = reactive({
            callbackObjType: '',
            checkingResult: [],
        });
        /*onMounted(()=>{
            nextTick(() => {
                (document.getElementsByClassName('table-box')[0] as any).style.height = window.innerHeight - 220 - searchCon.value.$el.scrollHeight + 'px';
                const height = (document.getElementsByClassName('table-box')[0] as any).style.height;
                const heightNum = parseInt(height.substr(0, height.indexOf('px')));
                // tableHeight.value = heightNum - 110;
                tableHeight.value = heightNum - 500;
            })
            console.log(162,(document.getElementsByClassName('table-box')[0] as any))
        })*/
        nextTick(() => {
            console.log('123456', searchCon.value);
            console.log('123456', searchCon.value.$el);
            (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height =
                window.innerHeight - 220 - searchCon.value.scrollHeight + 'px';
            const height = (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height;
            const heightNum = parseInt(height.substr(0, height.indexOf('px')));
            console.log('162', heightNum);
            // tableHeight.value = heightNum - 110;
            tableHeight.value = heightNum - 100;
        });
        const rowSelection = reactive<{
            selectedRowKeys: [];
            selectedRowData: AFCandLCApproveTableData[];
            onChange: Function;
            columnWidth: string;
        }>({
            onChange: (
                selectedRowKeys: [],
                selectedRows: AFCandLCApproveTableData[]
            ) => {
                rowSelection.selectedRowKeys = selectedRowKeys;
                rowSelection.selectedRowData = selectedRows;
            },
            selectedRowKeys: [],
            selectedRowData: [],
            columnWidth: '40px',
        });
        const doGetListData = () => {
            getDelayCallBackRuleHistory().then((res) => {
                console.log(res);
                queryListData.value = res ? res : [];
            });
            /*queryListData.value=[
                {
                    userName:'reawenclow',
                    idcard:'123456789123',
                    dealerCode:'caad',
                    dealerName:'cawwad',
                    eligiblePeriod:'cawwad',
                    file:'doc',
                    checkingResult:'checking',
                    comments:'aaaa',
                },
                {
                    userName:'reawenclow',
                    idcard:'123456789123',
                    dealerCode:'caad',
                    dealerName:'cawwad',
                    eligiblePeriod:'cawwad',
                    file:'doc',
                    checkingResult:'checking',
                    comments:'aaaa',
                },
                {
                    userName:'reawenclow',
                    idcard:'123456789123',
                    dealerCode:'caad',
                    dealerName:'cawwad',
                    eligiblePeriod:'cawwad',
                    file:'doc',
                    checkingResult:'checking',
                    comments:'aaaa',
                },
                {
                    userName:'reawenclow',
                    idcard:'123456789123',
                    dealerCode:'caad',
                    dealerName:'cawwad',
                    eligiblePeriod:'cawwad',
                    file:'doc',
                    checkingResult:'checking',
                    comments:'aaaa',
                },
                {
                    userName:'reawenclow',
                    idcard:'123456789123',
                    dealerCode:'caad',
                    dealerName:'cawwad',
                    eligiblePeriod:'cawwad',
                    file:'doc',
                    checkingResult:'checking',
                    comments:'aaaa',
                },
                {
                    userName:'reawenclow',
                    idcard:'123456789123',
                    dealerCode:'caad',
                    dealerName:'cawwad',
                    eligiblePeriod:'cawwad',
                    file:'doc',
                    checkingResult:'checking',
                    comments:'aaaa',
                },
                {
                    userName:'reawenclow',
                    idcard:'123456789123',
                    dealerCode:'caad',
                    dealerName:'cawwad',
                    eligiblePeriod:'cawwad',
                    file:'doc',
                    checkingResult:'checking',
                    comments:'aaaa',
                },
                {
                    userName:'reawenclow',
                    idcard:'123456789123',
                    dealerCode:'caad',
                    dealerName:'cawwad',
                    eligiblePeriod:'cawwad',
                    file:'doc',
                    checkingResult:'checking',
                    comments:'aaaa',
                },
                {
                    userName:'reawenclow',
                    idcard:'123456789123',
                    dealerCode:'caad',
                    dealerName:'cawwad',
                    eligiblePeriod:'cawwad',
                    file:'doc',
                    checkingResult:'checking',
                    comments:'aaaa',
                },
                {
                    userName:'reawenclow',
                    idcard:'123456789123',
                    dealerCode:'caad',
                    dealerName:'cawwad',
                    eligiblePeriod:'cawwad',
                    file:'doc',
                    checkingResult:'checking',
                    comments:'aaaa',
                },
                {
                    userName:'reawenclow',
                    idcard:'123456789123',
                    dealerCode:'caad',
                    dealerName:'cawwad',
                    eligiblePeriod:'cawwad',
                    file:'doc',
                    checkingResult:'checking',
                    comments:'aaaa',
                },
            ]*/
        };
        doGetListData();
        const radioChange = () => {
            console.log(formLst.callbackObjType);
            getPeriody({ type: formLst.callbackObjType }).then((res) => {
                console.log('res293', res);
                selectOpations.value.length = 0;
                const periodTimeArr = ref<any>([]);
                if (res) {
                    // console.log(res)
                    res.forEach((resItem: any, resIndex: any) => {
                        periodTimeArr.value.push(resItem);
                    });
                }
                console.log('302', periodTimeArr.value);
                selectOpations.value = periodTimeArr.value;
            });
        };
        const disabledDate = (current: Moment) => {
            // selectOpations
            // dateCompare()
            // console.log(current)
            let isShow = true;
            const currentDate = current.format('YYYY/MM');
            // console.log(337,selectOpations.value)
            /*const iStart = '2021/02/01'
            const iEnd = '2021/02/30'
            if (!(dateCompare(iStart,currentDate)==='1'||dateCompare(iEnd,currentDate)==='-1')){
                debugger
                isShow=false
            }*/
            // console.log(337,iStart,currentDate)
            selectOpations.value.forEach((i: any, ind: any) => {
                /*const selectS=moment(i.periodFrom,'YYYY/MM').valueOf()
                const selectE=moment(i.periodTo,'YYYY/MM').valueOf()
                console.log('313',selectS,selectE)
                if (!(current > moment(new Date(selectS), 'YYYY/MM')||current < moment(new Date(selectE), 'YYYY/MM'))){
                    console.log(current)
                }*/
                const iStart = i.periodFrom + '/01';
                let iEnd = '';
                const year = parseInt(i.periodTo.split('/')[0]);
                const mon = parseInt(i.periodTo.split('/')[1]);
                if (
                    mon === 1 ||
                    mon == 3 ||
                    mon == 5 ||
                    mon == 7 ||
                    mon == 8 ||
                    mon == 10 ||
                    mon == 12
                ) {
                    iEnd = i.periodTo + '/31';
                } else if (mon === 4 || mon == 6 || mon == 9 || mon == 11) {
                    iEnd = i.periodTo + '/30';
                } else {
                    if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
                        iEnd = i.periodTo + '/29';
                    } else {
                        iEnd = i.periodTo + '/28';
                    }
                }
                // console.log(359,i)
                // const iStart = i.periodFrom
                // const iEnd = i.periodTo
                // const iStart = '2021/11/01'
                // const iEnd = '2021/11/30'
                console.log(333, i);
                if (
                    !(
                        dateCompare(iStart, currentDate) === '1' ||
                        dateCompare(iEnd, currentDate) === '-1'
                    )
                ) {
                    isShow = false;
                }
            });
            /* if(this.selectPriceDate){
                const selectV = moment(this.selectPriceDate, 'YYYY-MM-DD').valueOf()
                return current > moment(new Date(selectV+this.offsetDays), 'YYYY-MM-DD') ||
                    current < moment(new Date(selectV-this.offsetDays), 'YYYY-MM-DD')
            }else{
                return false
            }*/
            // return current < moment(new Date(moment().format('YYYY/MM/DD')))
            // return current===null
            if (!isShow) {
                return true;
            } else {
                return false;
            }
        };
        const periodChange = () => {
            console.log(formLst.periodSelect);
            if (formLst.periodSelect) {
                selectPeriodFrom.value = formLst.periodSelect.split('-')[0];
                selectPeriodTo.value = formLst.periodSelect.split('-')[1];
            } else {
                selectPeriodFrom.value = '';
                selectPeriodTo.value = '';
            }
        };
        const handleAdd = () => {
            // let isCanAdd = false
            if (formLst.callbackObjType) {
                // console.log(388,formLst.periodSelect)
                if (formLst.periodSelect !== null) {
                    let isRepeat = false;
                    queryListData.value.forEach((i: any, ind: any) => {
                        console.log('340', i);
                        // console.log('341',i.period.split('-')[0],i.period.split('-')[1])
                        const iStart = i.period.split('-')[0] + '/01';
                        let iEnd = '';
                        // console.log(i.period.split('-')[1].split('/'))
                        const year = parseInt(
                            i.period.split('-')[1].split('/')[0]
                        );
                        const mon = parseInt(
                            i.period.split('-')[1].split('/')[1]
                        );
                        if (
                            mon === 1 ||
                            mon == 3 ||
                            mon == 5 ||
                            mon == 7 ||
                            mon == 8 ||
                            mon == 10 ||
                            mon == 12
                        ) {
                            iEnd = i.period.split('-')[1] + '/31';
                        } else if (
                            mon === 4 ||
                            mon == 6 ||
                            mon == 9 ||
                            mon == 11
                        ) {
                            iEnd = i.period.split('-')[1] + '/30';
                        } else {
                            if (
                                (year % 4 == 0 && year % 100 != 0) ||
                                year % 400 == 0
                            ) {
                                iEnd = i.period.split('-')[1] + '/29';
                            } else {
                                iEnd = i.period.split('-')[1] + '/28';
                            }
                        }
                        // console.log(359, iStart,iEnd)

                        if (
                            formLst.callbackObjType === 'Claim' &&
                            i.callBackObject === 'Claim'
                        ) {
                            if (formLst.periodSelect[0]) {
                                if (
                                    !(
                                        dateCompare(
                                            formLst.periodSelect[1],
                                            iStart
                                        ) === '-1' ||
                                        dateCompare(
                                            formLst.periodSelect[0],
                                            iEnd
                                        ) === '1'
                                    )
                                ) {
                                    if (i.status === '1') {
                                        isRepeat = true;
                                    }
                                }
                            }
                        }
                        if (
                            formLst.callbackObjType === 'Retail' &&
                            i.callBackObject === 'Retail'
                        ) {
                            if (formLst.periodSelect[0]) {
                                if (
                                    !(
                                        dateCompare(
                                            formLst.periodSelect[1],
                                            iStart
                                        ) === '-1' ||
                                        dateCompare(
                                            formLst.periodSelect[0],
                                            iEnd
                                        ) === '1'
                                    )
                                ) {
                                    if (i.status === '1') {
                                        isRepeat = true;
                                    }
                                }
                            }
                        }
                    });
                    /*if (formLst.callbackObjType&&formLst.periodSelect[0]){
                        isCanAdd=true
                    }*/
                    if (isRepeat) {
                        message.error('The rule already exist');
                    } else {
                        const params = {
                            callBackObject: formLst.callbackObjType,
                            periodFrom: formLst.periodSelect[0].format(
                                'YYYY/MM/DD'
                            ),
                            periodTo: formLst.periodSelect[1].format(
                                'YYYY/MM/DD'
                            ),
                        };
                        // console.log(441,params)
                        addCallBackRule(params).then((res) => {
                            console.log(res);
                            formLst.callbackObjType = '';
                            formLst.periodSelect = null;
                            doGetListData();
                        });
                    }
                } else {
                    message.error('Please select Call Back Period first.');
                }
            } else {
                message.error('Please select Call Back Object first.');
            }
        };
        const handleClose = (e: any) => {
            console.log(e);
            closeRuleHistory({ id: e.id }).then((res) => {
                console.log(res);
                doGetListData();
            });
        };
        const monthSelect = (val: Moment[], mode: string[]) => {
            // console.log(470,val)
            // console.log(471,formLst.periodSelect)
            /*value.value = val;*/
            formLst.periodSelect = val;
            dateMode.value = [
                mode[0] === 'date' ? 'month' : mode[0],
                mode[1] === 'date' ? 'month' : mode[1],
            ];
        };

        return {
            dateMode,
            monthSelect,
            disabledDate,
            handleClose,
            handleAdd,
            selectPeriodTo,
            selectPeriodFrom,
            periodChange,
            selectOpations,
            radioChange,
            formLst,
            doGetListData,
            rowSelection,
            searchCon,
            tableHeight,
            queryListData,
            queryListParams,
            tableWidth,
            columns,
        };
    },
});
