import { fetchGet,fetchDelete ,fetchPost,fetchGetMess ,fetchPostPic} from '../index';
import downloadFile from "@/utils/claim/downloadFile";
import axios from 'axios'

//生成SICCO Checing Deatail的列表
export const getSICCOChecingDetailList = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchPost('/claimapi/checking/page', params)
}

//获取delay call back rule history列表
export const getDelayCallBackRuleHistory= (): Promise<any> => {
    return fetchGet(`/claimapi/callback/rule/list`)
}
//获取activePeriod列表
export const getPeriody= (params={type: ''} ): Promise<any> => {
    return fetchGet(`/claimapi/callback/rule/activePeriod/${params.type}`,params)
}
//Add操作
export const addCallBackRule= (params={} ): Promise<any> => {
    return fetchPost(`/claimapi/callback/rule/add`,params)
}

//Close操作
export const closeRuleHistory= (params={id:''} ): Promise<any> => {
    return fetchPost(`/claimapi/callback/rule/close/${params.id}`,params)
}

